<template>
  <div>
    <div class="title">{{ $t("tipsI18n.AssetsTips") }}</div>
    <el-table :data="tableAssets">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column :label="$t('tableI18n.OrderCode')" prop="id" align="center" width="200"></el-table-column>
      <el-table-column
        :label="$t('tableI18n.CoreUserCompanyName')"
        prop="paymentName"
        align="center"
      ></el-table-column>
      <el-table-column prop="amount" align="center" width="160">
        <template slot="header">
          {{ $t('tableI18n.OrderAmount') }}
          <br />
          {{ $t('unitI18n.MillionUsDollars') }}
        </template>
        <template v-slot="scope">
          {{ scope.row.amount | toThousandFilterTwo }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.OrderDate')" prop="dueDate" align="center">
        <template v-slot="scope">{{ scope.row.dueDate }}</template>
      </el-table-column>
      <el-table-column prop="expiryDays" align="center">
        <template slot="header">{{ $t('tableI18n.OrderDays') }} {{ $t('unitI18n.DaysParentheses') }}</template>
        <template v-slot="scope">
          <span
            v-if="scope.row.status == optionsStatus[3].value || scope.row.status == optionsStatus[4].value"
          >--</span>
          <span
            v-else
            :class="{'text-red' : Number(scope.row.expiryDays) < 1}"
          >{{ scope.row.expiryDays }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.Status')" prop="status" align="center" width="100">
        <template v-slot="scope">{{ scope.row.statusText }}</template>
      </el-table-column>
      <el-table-column :label="$t('tableI18n.Operation')" align="center">
        <template v-slot="scope">
          <el-link
            class="list-opt"
            :underline="false"
            type="primary"
            @click="toDetail('/assets-list/assets-info', scope.row.id)"
          >{{ $t('btnI18n.See') }}</el-link>
          <el-link
            v-if="(scope.row.status).toString() === optionsStatus[1].value"
            class="list-opt"
            type="primary"
            :underline="false"
            @click="toDetail('/financing-list/financing-create', scope.row.id)"
          >{{ $t('btnI18n.ApplyFinancing') }}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import AdPagination from "@/components/ad-pagination";
import filter from "@/filters";
export default {
  name: "assets-finance",
  components: {
    AdPagination
  },
  data() {
    return {
      optionsStatus: this.$enums.ASSETS_STATUS,
      optionsValStatus: 'FINANCING',
      tableAssets: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      tableLoading: false
    };
  },
  created() {
    this.getAssetsList();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getAssetsList();
    },
    getAssetsList() {
      this.tableLoading = true;
      const data = {
        status: '1',
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      this.$axios.get('/v1/supplier/receivable-orders', { params: data }).then((response) => {
        if (response.code === 0 || response.code === '0') {
          this.tableAssets = response.data.rows || [];
          this.tableTotal = response.data.count || 0;
          this.tableLoading = false;
          this.tableAssets.map((item) => {
            item.statusText = filter.filterAssetsStatus((item.status).toString());
            item.amount = Number(item.amount) / this.$enums.UNIT_MILLION;
          });
        } else {
          this.tableLoading = false;
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => {
        this.tableLoading = false;
      });
    },
    toDetail(url, id) {
      this.$router.push({ path: url, query: { id: id } });
    }
  }
};
</script>

<style scoped lang="scss">
.list-opt {
  margin: 0 5px;
}
</style>
